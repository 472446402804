import type { IStore, INav } from '@types';

export const useMainStore = defineStore('mainStore', () => {
  const pending = ref<boolean>(false);
  const store = ref<IStore>();
  const stores = ref<IStore[]>([]);
  const headerNav = ref([] as INav[]);
  const footerNav = ref([] as INav[]);

  return {
    pending,
    store,
    stores,
    headerNav,
    footerNav,
  };
});
