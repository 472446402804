<template>
  <LocaleLink
    :to="`/event/${data?.id}`"
    :class="['article-tile', { 'article-tile--horizontal': variant === 'horizontal' }]"
    :aria-label="data?.name"
  >
    <picture class="article-tile__picture">
      <template v-if="variant === 'horizontal'">
        <source :srcset="`${data?.image?.md} 1x, ${data?.image?.md2} 2x`" />
      </template>

      <template v-else>
        <source
          :srcset="`${data?.image?.sm || data?.image?.md} 1x, ${data?.image?.sm2 || data?.image?.md2} 2x`"
          media="(max-width: 767px)"
        />

        <source
          :srcset="`${data?.image?.md} 1x, ${data?.image?.md2} 2x`"
          media="(min-width: 768px)"
        />
      </template>

      <img
        :src="data?.image?.md"
        :alt="data?.name"
        :title="data?.name"
        class="article-tile__img"
        loading="lazy"
        width="382"
        height="312"
      />
    </picture>

    <div class="article-tile__content">
      <h4 class="article-tile__title">{{ data?.name }}</h4>

      <div
        v-if="data?.description"
        class="article-tile__text"
        v-html="data.description"
      />

      <div
        v-if="datePublished"
        class="article-tile__date"
      >
        {{ datePublished }}
      </div>
    </div>
  </LocaleLink>
</template>

<script setup lang="ts">
import { LocaleLink } from '@shared/LocaleLink';
import type { IEvent } from '@types';

interface IProps {
  data: IEvent;
  variant?: 'horizontal';
}

const props = defineProps<IProps>();

const { locale, locales } = useI18n();
const currentLocale = computed(() => locales.value.filter((loc) => loc.code === locale.value)?.[0]);

const datePublished = computed(() => {
  return (
    props.data?.datePublished &&
    new Date(props.data.datePublished).toLocaleString(currentLocale.value?.language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  );
});
</script>

<style lang="scss" src="./ArticleTile.scss" />
