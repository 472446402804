<template>
  <div class="error">
    <div class="container">
      <div class="error__image">
        <img
          :src="'/images/404.jpg'"
          :alt="`${$t('error.error')} ${error?.statusCode}`"
          :title="`${$t('error.error')} ${error?.statusCode}`"
          loading="lazy"
        />
      </div>

      <div class="error__content">
        <div class="h2 error__title">{{ $t('error.error') }} {{ error?.statusCode }}</div>

        <div
          v-if="statusMessage"
          class="h1"
        >
          {{ statusMessage }}
        </div>

        <p v-if="message">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { NuxtError } from '#app';

interface IProps {
  error?: NuxtError;
}

const props = defineProps<IProps>();

const { t: $t } = useI18n();

const is404 = computed(() => props?.error?.statusCode === 404);

const statusMessage = is404.value ? $t('error.404.statusMessage') : props.error?.statusMessage;

const message = is404.value ? $t('error.404.message') : props.error?.message;
</script>

<style lang="scss" src="./Error.scss" />
