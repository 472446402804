<template>
  <NuxtLayout>
    <Error v-bind="$attrs" />

    <div class="container">
      <SliderTiles
        v-if="data?.totalCount"
        :title="$t('sliders.newsAndEvents')"
        routeMore="/events"
        :list="data?.events"
        variant="promo"
      >
        <template #slide="{ item }">
          <ArticleTile :data="item" />
        </template>
      </SliderTiles>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { Error } from '@widgets/Error';
import { SliderTiles } from '@widgets/SliderTiles';
import { ArticleTile } from '@entities/ArticleTile';

const { addSeoMeta } = useSeo();

const { locale, t: $t } = useI18n();

const { data }: any = await useAsyncData(() => fetchApiGet('/events'), {
  watch: [locale],
});

addSeoMeta({
  title: $t('error.error'),
});
</script>
