interface ISeoMeta {
  logo?: string;
  title?: string;
  description?: string;
  siteName?: string;
}

export const useSeoStore = defineStore('seoStore', () => {
  const meta = ref<ISeoMeta>();

  return {
    meta,
  };
});
