export default defineNuxtRouteMiddleware((to) => {
  const localePath = useLocalePath();
  const { locale } = useNuxtApp().$i18n;
  const _order = useCookie('_order');
  const _liqpay = useCookie('_liqpay');
  const _subscription = useCookie('_subscription');
  const _unsubscriptionAuthor = useCookie('_unsubscriptionAuthor');

  to.path = to.path.replace(`/${locale.value}/`, '/');

  if (Number(to?.query?.page) <= 1) {
    const query = to.query;
    delete query.page;

    return navigateTo({ query }, { replace: true, redirectCode: 301 });
  }

  if (to.path === '/pages' || to.path === '/pages/') {
    return navigateTo({ path: localePath('/') }, { replace: true, redirectCode: 301 });
  } else if (to.path === '/event' || to.path === '/event/') {
    return navigateTo({ path: localePath('/events') }, { replace: true, redirectCode: 301 });
  } else if (to.path === '/book' || to.path === '/book/') {
    return navigateTo({ path: localePath('/books') }, { replace: true, redirectCode: 301 });
  } else if ((to.path === '/search' || to.path === '/search/') && !to.query?.search) {
    return navigateTo({ path: localePath('/books') }, { replace: true, redirectCode: 301 });
  } else if (to.path === '/cabinet' || to.path === '/cabinet/') {
    return navigateTo({ path: localePath('/cabinet/account') }, { replace: true, redirectCode: 301 });
  } else if ((to.path === '/checkout/success' || to.path === '/checkout/success/') && !_order.value) {
    return navigateTo(localePath('/'));
  } else if (to.path === '/checkout/payment' || to.path === '/checkout/payment/') {
    return navigateTo(localePath('/'));
  } else if (
    (to.path === '/checkout/payment/liqpay' || to.path === '/checkout/payment/liqpay') &&
    !_liqpay.value &&
    _order.value
  ) {
    return navigateTo(localePath('/checkout/success'));
  } else if ((to.path === '/newsletter/success' || to.path === '/newsletter/success/') && !_subscription.value) {
    return navigateTo(localePath('/'));
  } else if (
    (to.path === '/author_product_alert/success' || to.path === '/author_product_alert/success/') &&
    !_unsubscriptionAuthor.value
  ) {
    return navigateTo(localePath('/'));
  }
});
