import type { IAuthData } from '@types';

export const useAuthStore = defineStore('authStore', () => {
  const { store } = storeToRefs(useMainStore());

  const pending = ref<boolean>(false);
  const token = ref<string | null | undefined>();
  const visitor = ref<string | null | undefined>();
  const authData = ref<IAuthData>({});
  const otpLength = computed(() => Number(store.value?.otpLength));
  const otpTimerAmount = computed(() => Number(store.value?.otpTimerAmount));
  const otpTimer = ref<number>(0);
  const isOtpGenerated = ref<boolean>(false); // is otp generated after send phone number
  const isAuth = ref<boolean>(false); // is user auth
  const isAuthModal = ref<boolean>(false); // is modal auth component opened
  const isGoogleAuth = computed(() => store.value?.isGoogleAuth); // is google auth enabled
  const isFacebookAuth = computed(() => store.value?.isFacebookAuth); // is facebook auth enabled
  const isFacebookLoaded = ref<boolean>(false); // is facebook auth button is loaded
  const callback = ref<Function | undefined>();

  return {
    pending,
    token,
    visitor,
    authData,
    otpLength,
    otpTimerAmount,
    otpTimer,
    callback,
    isAuth,
    isAuthModal,
    isGoogleAuth,
    isFacebookAuth,
    isFacebookLoaded,
    isOtpGenerated,
  };
});
