<template>
  <div
    ref="slider"
    :class="['slider-tiles', 'swiper', classComputed]"
  >
    <div class="slider-tiles__header">
      <h2
        v-if="title"
        class="h2 slider-tiles__title"
      >
        {{ title }}
      </h2>

      <LocaleLink
        v-if="routeMore"
        :to="routeMore"
        class="slider-tiles__more"
      >
        <span>{{ $t('sliders.viewAll') }}</span>
        <Icon name="arrow-right" />
      </LocaleLink>

      <div class="swiper-nav">
        <div
          ref="sliderPrev"
          class="swiper-arrow"
        >
          <Icon name="arrow-thin-left" />
        </div>

        <div
          ref="sliderNext"
          class="swiper-arrow"
        >
          <Icon name="arrow-thin-right" />
        </div>
      </div>
    </div>

    <div class="slider-tiles__wrapper swiper-wrapper">
      <div
        v-for="(item, idx) in list"
        :key="idx"
        class="slider-tiles__item swiper-slide"
      >
        <slot
          name="slide"
          :item="item"
          :idx="item.id"
        />
      </div>
    </div>

    <div
      ref="sliderScrollbar"
      class="swiper-scrollbar"
    />
  </div>
</template>

<script lang="ts" setup>
import { Swiper } from 'swiper';
import type { CSSSelector, SwiperOptions } from 'swiper/types';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { LocaleLink } from '@shared/LocaleLink';
import { Icon } from '@shared/Icon';

interface IProps {
  list: any[];
  title?: string;
  routeMore?: string;
  variant?: 'products' | 'promo';
}

const props = withDefaults(defineProps<IProps>(), {
  title: undefined,
  routeMore: undefined,
  variant: 'products',
});

const { t: $t } = useI18n();

const classComputed = computed(() => {
  return [props.variant ? `slider-tiles--${props.variant}` : ''];
});

const swiperInstance = ref<Swiper | null>(null);
const slider = ref<HTMLElement | CSSSelector | ''>('');
const sliderPrev = ref<CSSSelector | HTMLElement | null>(null);
const sliderNext = ref<CSSSelector | HTMLElement | null>(null);
const sliderScrollbar = ref<CSSSelector | HTMLElement | null>(null);

interface SliderOptions {
  [key: string]: SwiperOptions;
}
const sliderOptions: SliderOptions = {
  products: {
    slidesPerView: 'auto',
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
      1200: {
        slidesPerView: 5,
      },
      1400: {
        slidesPerView: 6,
      },
    },
  },
  promo: {
    slidesPerView: 'auto',
    spaceBetween: 25,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 42,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 33,
      },
      1400: {
        slidesPerView: 5,
        spaceBetween: 46,
      },
    },
  },
};

onMounted(() => {
  swiperInstance.value = new Swiper(slider.value, {
    modules: [Navigation, Pagination, Scrollbar],
    speed: 500,
    ...sliderOptions[props.variant],
    navigation: {
      nextEl: sliderNext.value,
      prevEl: sliderPrev.value,
    },
    scrollbar: {
      el: sliderScrollbar.value,
      draggable: true,
    },
  });
});

onBeforeUnmount(() => {
  if (swiperInstance.value) {
    swiperInstance.value.destroy();
  }
});
</script>

<style lang="scss" src="./SliderTiles.scss" />
