import type { LocationQuery } from 'vue-router';

export const useSeo = () => {
  const { locale: localeCode, locales } = useI18n();

  const { meta } = storeToRefs(useSeoStore());

  const { t: $t } = useI18n();

  const locale = computed(() =>
    locales.value?.find((locale: any) => locale.code === localeCode.value)?.language?.replace('-', '_')
  );

  const canonicalUrl = computed(() => {
    const { protocol, host, pathname } = useRequestURL();
    return `${protocol}//${host}${pathname}`;
  });

  const domainUrl = computed(() => useRequestURL().origin);

  const addSeoJson = (ldJson: Record<string, any>) => {
    useHead({
      script: [
        {
          type: 'application/ld+json',
          children: JSON.stringify(ldJson),
        },
      ],
    });
  };

  const addSeoMeta = (data?: any) => {
    useSeoMeta({
      title: data?.title || meta.value?.title || meta.value?.siteName,
      ogTitle: data?.title || meta.value?.title || meta.value?.siteName,
      description: data?.description || meta.value?.description,
      ogSiteName: meta.value?.siteName,
      ogUrl: canonicalUrl.value,
      ogLocale: locale.value,
      ogImage: data?.image || `${domainUrl.value}${meta.value?.logo}`,
      ogImageWidth: data?.imageWidth || 640,
      ogImageHeight: data?.imageHeight || 640,
      ogDescription: data?.description || meta.value?.description,
    });
  };

  const addSeoBreadcrumbs = (data?: Array<{ name?: string; path?: string }>) => {
    const result = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: data?.map((crumb, idx) => ({
        '@type': 'ListItem',
        position: idx + 1,
        item: {
          '@id': `${domainUrl.value}${crumb?.path}`,
          name: crumb?.name,
        },
      })),
    };

    addSeoJson(result);
  };

  const addSeoProduct = (data?: any) => {
    const result = {
      '@context': 'https://schema.org',
      '@type': 'Product',
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: data?.ratingValue,
        reviewCount: data?.reviewCount,
      },
      name: data?.name,
      description: data?.description,
      sku: data?.sku,
      brand: {
        '@type': 'Brand',
        name: data?.brand,
      },
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        price: data?.price,
        priceCurrency: data?.currency,
        url: `${domainUrl.value}${data?.url}`,
      },
    } as any;

    if (data?.images?.length) {
      result.image = data.images.map((image: any, idx: number) => {
        return {
          '@type': 'ImageObject',
          contentUrl: image?.url?.sm2 || image?.url?.sm || image?.url?.md || image?.url?.lg,
          name: `${image?.label} - ${$t('image').toLocaleLowerCase()} ${idx}`,
          description: `${image?.label} - ${$t('photo').toLocaleLowerCase()} ${idx}`,
          width: 450,
          height: 690,
        };
      });
    }

    if (data?.reviews?.length) {
      result.review = data.reviews.map((review: any) => {
        return {
          '@type': 'Review',
          author: {
            '@type': 'Person',
            name: review.nickname,
          },
          datePublished: review.createdAt,
          reviewBody: review.text,
          reviewRating: {
            '@type': 'Rating',
            ratingValue: review.ratingValue,
            bestRating: 5,
            worstRating: 1,
          },
        };
      });
    }

    addSeoJson(result);
  };

  const addSeoArticle = (data?: any) => {
    const result = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      headline: data?.headline,
      alternativeHeadline: data?.alternativeHeadline,
      description: data?.description,
      datePublished: data?.datePublished,
      dateCreated: data?.dateCreated,
      dateModified: data?.dateModified,
      articleBody: data?.articleBody,
      wordcount: data?.articleBody?.split(' ').filter((n: any) => {
        return n !== '';
      }).length,
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': canonicalUrl.value,
      },
      publisher: {
        '@type': 'Organization',
        name: meta.value?.siteName,
        logo: {
          '@type': 'ImageObject',
          url: `${domainUrl.value}${meta.value?.logo}`,
        },
      },
      author: {
        '@type': 'Person',
        name: meta.value?.siteName,
      },
    } as any;

    if (data?.image) {
      result.image = [
        {
          '@type': 'ImageObject',
          contentUrl: data.image,
          caption: data?.headline,
          width: 800,
          height: 600,
        },
      ];
    }

    addSeoJson(result);
  };

  const addSeoStore = (data?: any) => {
    const result = {
      '@context': 'https://schema.org',
      '@type': 'Store',
      url: domainUrl.value,
      priceRange: '$',
      name: data?.name,
      logo: `${domainUrl.value}${meta.value?.logo}`,
      image: data?.image || `${domainUrl.value}${meta.value?.logo}`,
      currenciesAccepted: data?.currenciesAccepted,
      paymentAccepted: 'cash, credit card',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: data.phone,
          contactType: 'customer support',
        },
      ],
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: data?.daysOpen,
          opens: data?.hoursOpen,
          closes: data?.hoursClose,
        },
      ],
    } as any;

    if (data?.addresses?.length) {
      result.address = data.addresses.map((address: any) => {
        return {
          '@type': 'PostalAddress',
          '@id': domainUrl.value,
          name: address?.name,
          addressLocality: address?.city,
          streetAddress: address?.address,
          telephone: data.phone,
          addressCountry: 'UA',
        };
      });
    }

    addSeoJson(result);
  };

  const addSeoSearch = () => {
    const result = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      url: domainUrl.value,
      potentialAction: [
        {
          '@type': 'SearchAction',
          target: {
            '@type': 'EntryPoint',
            urlTemplate: `${domainUrl.value}/search?search={search_term_string}`,
          },
          'query-input': 'required name=search_term_string',
        },
      ],
    } as any;

    addSeoJson(result);
  };

  const addNoIndex = ({ query, force }: { query?: LocationQuery; force?: boolean }): void => {
    if (query) {
      const queries = Object.keys(query);
      const queriesMap = ['sort', 'format', 'author', 'language', 'publisher', 'price', 'cover', 'category', 'period'];

      useHead({
        meta: [
          {
            name: 'robots',
            content: queriesMap.some((item) => queries?.includes(item)) ? 'noindex' : 'all',
          },
        ],
      });
    } else if (force) {
      useHead({
        meta: [
          {
            name: 'robots',
            content: 'noindex',
          },
        ],
      });
    }
  };

  return {
    addSeoJson,
    addSeoMeta,
    addSeoBreadcrumbs,
    addSeoProduct,
    addSeoArticle,
    addSeoStore,
    addSeoSearch,
    addNoIndex,
  };
};
