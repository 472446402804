<template>
  <NuxtLink :to="to ? localePath(to) : undefined">
    <slot />
  </NuxtLink>
</template>

<script lang="ts" setup>
interface IProps {
  to?: any;
}

defineProps<IProps>();

const localePath = useLocalePath();
</script>
