export const useCartStore = defineStore('cartStore', () => {
  const pending = ref<boolean>(false);
  const pendingOpenMap = ref<boolean>(false);
  const pendingCheckout = ref<boolean>(false);

  const cart = ref<any>();
  const cartId = ref<string | null | undefined>();
  const billing = ref<any>();
  const shipping = ref<any>();
  const shippingMethods = ref<Array<any>>();
  const shippingMethod = ref<any>();
  const paymentMethods = ref<Array<any>>();
  const paymentMethod = ref<any>();
  const comment = ref<string>();
  const isAnotherRecipient = ref<boolean>(false);
  const isCommentOpened = ref<boolean>(false);
  const isCommentError = ref<boolean>(false);

  const isCartModal = ref<boolean>(false);

  const isOpenedMap = ref<boolean>(false);
  const isContactsOpened = ref<boolean>(false);
  const isContactsComplete = computed(() =>
    cart.value?.isVirtual
      ? !!(billing.value?.phone && billing.value?.firstname && billing.value?.lastname)
      : !!(shipping.value?.phone && shipping.value?.firstname && shipping.value?.lastname)
  );
  const isShippingOpened = ref<boolean>(false);
  const isShippingComplete = computed(
    () =>
      !!(
        isContactsComplete.value &&
        !!shipping.value?.cityId &&
        !!shippingMethod.value?.code &&
        (!!shipping.value?.novaposhtaWarehouseRef ||
          !!shipping.value?.selfpickupBookshopId ||
          !!shipping.value?.street?.[0]?.length)
      )
  );
  const isPaymentOpened = computed(
    () =>
      !isContactsOpened.value &&
      isContactsComplete.value &&
      (cart.value?.isVirtual || (!isShippingOpened.value && isContactsComplete.value))
  );
  const isPaymentComplete = computed(() => !!paymentMethod.value?.code);

  return {
    pending,
    pendingOpenMap,
    pendingCheckout,
    cart,
    cartId,
    billing,
    shipping,
    shippingMethods,
    shippingMethod,
    paymentMethods,
    paymentMethod,
    comment,
    isOpenedMap,
    isAnotherRecipient,
    isCartModal,
    isContactsOpened,
    isContactsComplete,
    isShippingOpened,
    isShippingComplete,
    isPaymentOpened,
    isPaymentComplete,
    isCommentOpened,
    isCommentError,
  };
});
