export const useMain = () => {
  const $route = useRoute();

  const { pending, stores, store, footerNav, headerNav } = storeToRefs(useMainStore());
  const { categories } = storeToRefs(useCatalogStore());
  const { pending: pendingUser, user } = storeToRefs(useUserStore());
  const { pending: pendingWishlist, wishlist } = storeToRefs(useWishlistStore());
  const { pending: pendingCart, cart } = storeToRefs(useCartStore());

  const getMain = async () => {
    pending.value = true;

    try {
      const result = await fetchApiGet('/main', {
        data: {
          hasCategories: categories.value?.length ? 1 : 0,
          lighthouseCheck: $route?.query['lighthouse-check'] ? 1 : 0,
        },
      });

      stores.value = result?.stores;
      store.value = result?.store;
      headerNav.value = result?.headerNav;
      footerNav.value = result?.footerNav;

      if (result?.categories) {
        categories.value = result?.categories;
      }

      return result;
    } finally {
      pending.value = false;
    }
  };

  const getMainUser = async () => {
    pendingUser.value = true;
    pendingWishlist.value = true;
    pendingCart.value = true;

    try {
      const result = await fetchApiGet('/main/user');

      user.value = result?.user;
      wishlist.value = result?.wishlist;
      cart.value = result?.cart;

      return result;
    } finally {
      pendingUser.value = false;
      pendingWishlist.value = false;
      pendingCart.value = false;
    }
  };

  return {
    getMain,
    getMainUser,
  };
};
