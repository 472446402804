<template>
  <component
    :is="tag"
    :class="['icon', `icon-${name}`]"
    :style="stylesComputed"
  >
    <IconSvg :name />
  </component>
</template>

<script lang="ts" setup>
interface IProps {
  name: string;
  tag?: keyof HTMLElementTagNameMap;
  color?: string;
  size?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  tag: 'i',
  color: undefined,
  size: undefined,
});

const stylesComputed = computed(() => {
  return [props.size && `width: ${props.size}; height: ${props.size}`, props.color && `color: ${props.color}`];
});
</script>

<style lang="scss" src="./Icon.scss" />
