import type { ICategory, IAuthor, IFilter, IFilterActive, IProduct } from '@types';

export const useCatalogStore = defineStore('catalogStore', () => {
  const pending = ref<boolean>(false);
  const pendingCategories = ref<boolean>(false);
  const categories = ref<Array<ICategory>>();
  const category = ref<ICategory>();
  const author = ref<IAuthor>();
  const products = ref<IProduct[]>();
  const productsTotal = ref<number>(0);
  const pagination = ref<any>();
  const sort = ref<any>();
  const filters = ref<IFilter[]>();
  const filtersActive = ref<IFilterActive[]>();

  const reset = () => {
    category.value = undefined;
  };

  return {
    pending,
    pendingCategories,
    categories,
    category,
    author,
    products,
    productsTotal,
    pagination,
    sort,
    filters,
    filtersActive,
    reset,
  };
});
