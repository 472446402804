import { default as _91code_93rk2yMRykmuMeta } from "/var/www/app/src/pages/author_product_alert/subscriber/unsubscribe/id/[id]/code/[code].vue?macro=true";
import { default as index3RzS86ztjjMeta } from "/var/www/app/src/pages/author_product_alert/success/index.vue?macro=true";
import { default as indexPy5urI9BEMMeta } from "/var/www/app/src/pages/authors/[alias]/index.vue?macro=true";
import { default as indexqU3RhlgqPoMeta } from "/var/www/app/src/pages/authors/index.vue?macro=true";
import { default as indexhfFHOJAUkHMeta } from "/var/www/app/src/pages/book/[urlKey]/index.vue?macro=true";
import { default as indexF7NaPDVvmvMeta } from "/var/www/app/src/pages/books/index.vue?macro=true";
import { default as indexxQgHpETCXaMeta } from "/var/www/app/src/pages/cabinet/index/account/index.vue?macro=true";
import { default as indexIlyXI68qIfMeta } from "/var/www/app/src/pages/cabinet/index/index.vue?macro=true";
import { default as indexd7aiTdyehLMeta } from "/var/www/app/src/pages/cabinet/index/my-subscriptions/index.vue?macro=true";
import { default as indexKxQVaizKDCMeta } from "/var/www/app/src/pages/cabinet/index/orders/index.vue?macro=true";
import { default as indexN0wLbifmwVMeta } from "/var/www/app/src/pages/cabinet/index/report-availability/index.vue?macro=true";
import { default as indexfcF0uoc3PxMeta } from "/var/www/app/src/pages/cabinet/index/reviews/index.vue?macro=true";
import { default as indexNE77SxH5bwMeta } from "/var/www/app/src/pages/cabinet/index/wishlist/index.vue?macro=true";
import { default as indexUlrHI3kofKMeta } from "/var/www/app/src/pages/cabinet/index.vue?macro=true";
import { default as indexYcrqCE0QJeMeta } from "/var/www/app/src/pages/checkout/index/index.vue?macro=true";
import { default as indexRqFNwAcCX6Meta } from "/var/www/app/src/pages/checkout/index.vue?macro=true";
import { default as indexWswAvQ5HL0Meta } from "/var/www/app/src/pages/checkout/payment/index.vue?macro=true";
import { default as indexQ9qO4tiQwBMeta } from "/var/www/app/src/pages/checkout/payment/liqpay/index.vue?macro=true";
import { default as indexic5KFN6tIJMeta } from "/var/www/app/src/pages/checkout/success/index.vue?macro=true";
import { default as indexC5gn5zqmqHMeta } from "/var/www/app/src/pages/components/index.vue?macro=true";
import { default as indexW1gDMV0nLYMeta } from "/var/www/app/src/pages/event/[id]/index.vue?macro=true";
import { default as indexXEJRTobVqUMeta } from "/var/www/app/src/pages/events/index/[category]/index.vue?macro=true";
import { default as indexVl3V4FjMTVMeta } from "/var/www/app/src/pages/events/index/index.vue?macro=true";
import { default as index2ppU3nNmxtMeta } from "/var/www/app/src/pages/events/index.vue?macro=true";
import { default as indexnXYrRD4AbDMeta } from "/var/www/app/src/pages/index/index.vue?macro=true";
import { default as _91code_93wHFcJjFMw4Meta } from "/var/www/app/src/pages/newsletter/subscriber/confirm/id/[id]/code/[code].vue?macro=true";
import { default as indexV9qYbkHT0jMeta } from "/var/www/app/src/pages/newsletter/success/index.vue?macro=true";
import { default as index59XISL2jgzMeta } from "/var/www/app/src/pages/pages/index/[urlKey]/index.vue?macro=true";
import { default as indexRyqdah5Z04Meta } from "/var/www/app/src/pages/pages/index.vue?macro=true";
import { default as index1bN6xTfggNMeta } from "~/pages/books/index.vue?macro=true";
export default [
  {
    name: "author_product_alert-subscriber-unsubscribe-id-id-code-code___ua",
    path: "/author_product_alert/subscriber/unsubscribe/id/:id()/code/:code()",
    meta: _91code_93rk2yMRykmuMeta || {},
    component: () => import("/var/www/app/src/pages/author_product_alert/subscriber/unsubscribe/id/[id]/code/[code].vue")
  },
  {
    name: "author_product_alert-subscriber-unsubscribe-id-id-code-code___en",
    path: "/en/author_product_alert/subscriber/unsubscribe/id/:id()/code/:code()",
    meta: _91code_93rk2yMRykmuMeta || {},
    component: () => import("/var/www/app/src/pages/author_product_alert/subscriber/unsubscribe/id/[id]/code/[code].vue")
  },
  {
    name: "author_product_alert-success___ua",
    path: "/author_product_alert/success",
    component: () => import("/var/www/app/src/pages/author_product_alert/success/index.vue")
  },
  {
    name: "author_product_alert-success___en",
    path: "/en/author_product_alert/success",
    component: () => import("/var/www/app/src/pages/author_product_alert/success/index.vue")
  },
  {
    name: "authors-alias___ua",
    path: "/authors/:alias()",
    component: () => import("/var/www/app/src/pages/authors/[alias]/index.vue")
  },
  {
    name: "authors-alias___en",
    path: "/en/authors/:alias()",
    component: () => import("/var/www/app/src/pages/authors/[alias]/index.vue")
  },
  {
    name: "authors___ua",
    path: "/authors",
    component: () => import("/var/www/app/src/pages/authors/index.vue")
  },
  {
    name: "authors___en",
    path: "/en/authors",
    component: () => import("/var/www/app/src/pages/authors/index.vue")
  },
  {
    name: "book-urlKey___ua",
    path: "/book/:urlKey()",
    component: () => import("/var/www/app/src/pages/book/[urlKey]/index.vue")
  },
  {
    name: "book-urlKey___en",
    path: "/en/book/:urlKey()",
    component: () => import("/var/www/app/src/pages/book/[urlKey]/index.vue")
  },
  {
    name: "books___ua",
    path: "/books",
    meta: indexF7NaPDVvmvMeta || {},
    component: () => import("/var/www/app/src/pages/books/index.vue")
  },
  {
    name: "books___en",
    path: "/en/books",
    meta: indexF7NaPDVvmvMeta || {},
    component: () => import("/var/www/app/src/pages/books/index.vue")
  },
  {
    name: indexUlrHI3kofKMeta?.name,
    path: "/cabinet",
    meta: indexUlrHI3kofKMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index.vue"),
    children: [
  {
    name: "cabinet-index-account___ua",
    path: "account",
    meta: indexxQgHpETCXaMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/account/index.vue")
  },
  {
    name: "cabinet-index___ua",
    path: "",
    meta: indexIlyXI68qIfMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/index.vue")
  },
  {
    name: "cabinet-index-my-subscriptions___ua",
    path: "my-subscriptions",
    meta: indexd7aiTdyehLMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/my-subscriptions/index.vue")
  },
  {
    name: "cabinet-index-orders___ua",
    path: "orders",
    meta: indexKxQVaizKDCMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/orders/index.vue")
  },
  {
    name: "cabinet-index-report-availability___ua",
    path: "report-availability",
    meta: indexN0wLbifmwVMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/report-availability/index.vue")
  },
  {
    name: "cabinet-index-reviews___ua",
    path: "reviews",
    meta: indexfcF0uoc3PxMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/reviews/index.vue")
  },
  {
    name: "cabinet-index-wishlist___ua",
    path: "wishlist",
    meta: indexNE77SxH5bwMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/wishlist/index.vue")
  }
]
  },
  {
    name: indexUlrHI3kofKMeta?.name,
    path: "/en/cabinet",
    meta: indexUlrHI3kofKMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index.vue"),
    children: [
  {
    name: "cabinet-index-account___en",
    path: "account",
    meta: indexxQgHpETCXaMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/account/index.vue")
  },
  {
    name: "cabinet-index___en",
    path: "",
    meta: indexIlyXI68qIfMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/index.vue")
  },
  {
    name: "cabinet-index-my-subscriptions___en",
    path: "my-subscriptions",
    meta: indexd7aiTdyehLMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/my-subscriptions/index.vue")
  },
  {
    name: "cabinet-index-orders___en",
    path: "orders",
    meta: indexKxQVaizKDCMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/orders/index.vue")
  },
  {
    name: "cabinet-index-report-availability___en",
    path: "report-availability",
    meta: indexN0wLbifmwVMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/report-availability/index.vue")
  },
  {
    name: "cabinet-index-reviews___en",
    path: "reviews",
    meta: indexfcF0uoc3PxMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/reviews/index.vue")
  },
  {
    name: "cabinet-index-wishlist___en",
    path: "wishlist",
    meta: indexNE77SxH5bwMeta || {},
    component: () => import("/var/www/app/src/pages/cabinet/index/wishlist/index.vue")
  }
]
  },
  {
    name: indexRqFNwAcCX6Meta?.name,
    path: "/checkout",
    component: () => import("/var/www/app/src/pages/checkout/index.vue"),
    children: [
  {
    name: "checkout-index___ua",
    path: "",
    meta: indexYcrqCE0QJeMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/index/index.vue")
  }
]
  },
  {
    name: indexRqFNwAcCX6Meta?.name,
    path: "/en/checkout",
    component: () => import("/var/www/app/src/pages/checkout/index.vue"),
    children: [
  {
    name: "checkout-index___en",
    path: "",
    meta: indexYcrqCE0QJeMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/index/index.vue")
  }
]
  },
  {
    name: "checkout-payment___ua",
    path: "/checkout/payment",
    meta: indexWswAvQ5HL0Meta || {},
    component: () => import("/var/www/app/src/pages/checkout/payment/index.vue")
  },
  {
    name: "checkout-payment___en",
    path: "/en/checkout/payment",
    meta: indexWswAvQ5HL0Meta || {},
    component: () => import("/var/www/app/src/pages/checkout/payment/index.vue")
  },
  {
    name: "checkout-payment-liqpay___ua",
    path: "/checkout/payment/liqpay",
    meta: indexQ9qO4tiQwBMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/payment/liqpay/index.vue")
  },
  {
    name: "checkout-payment-liqpay___en",
    path: "/en/checkout/payment/liqpay",
    meta: indexQ9qO4tiQwBMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/payment/liqpay/index.vue")
  },
  {
    name: "checkout-success___ua",
    path: "/checkout/success",
    meta: indexic5KFN6tIJMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/success/index.vue")
  },
  {
    name: "checkout-success___en",
    path: "/en/checkout/success",
    meta: indexic5KFN6tIJMeta || {},
    component: () => import("/var/www/app/src/pages/checkout/success/index.vue")
  },
  {
    name: "components___ua",
    path: "/components",
    component: () => import("/var/www/app/src/pages/components/index.vue")
  },
  {
    name: "components___en",
    path: "/en/components",
    component: () => import("/var/www/app/src/pages/components/index.vue")
  },
  {
    name: "event-id___ua",
    path: "/event/:id()",
    component: () => import("/var/www/app/src/pages/event/[id]/index.vue")
  },
  {
    name: "event-id___en",
    path: "/en/event/:id()",
    component: () => import("/var/www/app/src/pages/event/[id]/index.vue")
  },
  {
    name: index2ppU3nNmxtMeta?.name,
    path: "/events",
    component: () => import("/var/www/app/src/pages/events/index.vue"),
    children: [
  {
    name: "events-index-category___ua",
    path: ":category()",
    component: () => import("/var/www/app/src/pages/events/index/[category]/index.vue")
  },
  {
    name: "events-index___ua",
    path: "",
    component: () => import("/var/www/app/src/pages/events/index/index.vue")
  }
]
  },
  {
    name: index2ppU3nNmxtMeta?.name,
    path: "/en/events",
    component: () => import("/var/www/app/src/pages/events/index.vue"),
    children: [
  {
    name: "events-index-category___en",
    path: ":category()",
    component: () => import("/var/www/app/src/pages/events/index/[category]/index.vue")
  },
  {
    name: "events-index___en",
    path: "",
    component: () => import("/var/www/app/src/pages/events/index/index.vue")
  }
]
  },
  {
    name: "index___ua",
    path: "/",
    component: () => import("/var/www/app/src/pages/index/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/var/www/app/src/pages/index/index.vue")
  },
  {
    name: "newsletter-subscriber-confirm-id-id-code-code___ua",
    path: "/newsletter/subscriber/confirm/id/:id()/code/:code()",
    meta: _91code_93wHFcJjFMw4Meta || {},
    component: () => import("/var/www/app/src/pages/newsletter/subscriber/confirm/id/[id]/code/[code].vue")
  },
  {
    name: "newsletter-subscriber-confirm-id-id-code-code___en",
    path: "/en/newsletter/subscriber/confirm/id/:id()/code/:code()",
    meta: _91code_93wHFcJjFMw4Meta || {},
    component: () => import("/var/www/app/src/pages/newsletter/subscriber/confirm/id/[id]/code/[code].vue")
  },
  {
    name: "newsletter-success___ua",
    path: "/newsletter/success",
    component: () => import("/var/www/app/src/pages/newsletter/success/index.vue")
  },
  {
    name: "newsletter-success___en",
    path: "/en/newsletter/success",
    component: () => import("/var/www/app/src/pages/newsletter/success/index.vue")
  },
  {
    name: "pages___ua",
    path: "/pages",
    component: () => import("/var/www/app/src/pages/pages/index.vue"),
    children: [
  {
    name: "pages-index-urlKey___ua",
    path: ":urlKey()",
    component: () => import("/var/www/app/src/pages/pages/index/[urlKey]/index.vue")
  }
]
  },
  {
    name: "pages___en",
    path: "/en/pages",
    component: () => import("/var/www/app/src/pages/pages/index.vue"),
    children: [
  {
    name: "pages-index-urlKey___en",
    path: ":urlKey()",
    component: () => import("/var/www/app/src/pages/pages/index/[urlKey]/index.vue")
  }
]
  },
  {
    name: "books.category___ua",
    path: "/books/:category*",
    meta: index1bN6xTfggNMeta || {},
    component: () => import("~/pages/books/index.vue")
  },
  {
    name: "books.category___en",
    path: "/en/books/:category*",
    component: () => import("~/pages/books/index.vue")
  },
  {
    name: "search___ua",
    path: "/search",
    component: () => import("~/pages/books/index.vue")
  },
  {
    name: "search___en",
    path: "/en/search",
    component: () => import("~/pages/books/index.vue")
  },
  {
    name: "search.category___ua",
    path: "/search/:category*",
    component: () => import("~/pages/books/index.vue")
  },
  {
    name: "search.category___en",
    path: "/en/search/:category*",
    component: () => import("~/pages/books/index.vue")
  }
]