<template>
  <div :class="['loader', classComputed]">
    <div class="loader__icon" />
    <slot />
  </div>
</template>

<script lang="ts" setup>
interface IProps {
  size?: 'sm' | 'md' | 'lg';
  background?: boolean;
  variant?: 'primary' | 'secondary';
  position?: 'fixed' | 'absolute';
}

const props = withDefaults(defineProps<IProps>(), {
  size: 'sm',
  background: true,
  variant: 'primary',
  position: 'absolute',
});

const classComputed = computed(() => {
  return [
    {
      'is-bg': props.background,
    },
    props.size && `loader--${props.size}`,
    props.variant && `loader--${props.variant}`,
    props.position && `loader--${props.position}`,
  ];
});
</script>

<style lang="scss" src="./Loader.scss" />
