import { MAX_AGE } from '@constants';
import type { IStore } from '@types';

const fetchApi = (requestUrl: string, options?: any): any => {
  const _token = useCookie('_token', { maxAge: MAX_AGE });
  const _visitor = useCookie('_visitor', { maxAge: MAX_AGE });
  const _cart = useCookie('_cart', { maxAge: MAX_AGE });

  const { stores } = storeToRefs(useMainStore());

  const { isAuth, token: tokenValue, visitor: visitorValue } = storeToRefs(useAuthStore());

  const { cartId: cartIdValue } = storeToRefs(useCartStore());

  const { locale: localeCode, locales }: any = useNuxtApp().$i18n;

  const locale = locales.value?.find((locale: any) => locale.code === localeCode.value)?.language?.replace('-', '_');

  const store =
    (stores.value?.find((store: IStore) => store.locale === locale) ?? stores.value?.[0])?.storeCode ?? 'default';

  const fetchDefaults = {
    baseURL: '/api',
    onRequest({ options }: any) {
      const token = tokenValue.value || _token.value;
      const visitor = visitorValue.value || _visitor.value;
      const cart = cartIdValue.value || _cart.value;

      options.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        locale,
        store,
        ...(cart ? { cart } : {}),
        ...(token ? { token } : {}),
        ...(!token && visitor ? { visitor } : {}),
        ...options.headers,
      } as any;
    },
    onResponse({ response }: any) {
      const token = response.headers.get('token');
      const visitor = response.headers.get('visitor');
      const cart = response.headers.get('cart');

      if (token) {
        tokenValue.value = token;
        _token.value = token;
        visitorValue.value = null;
        _visitor.value = null;
        isAuth.value = true;
      } else if (!token && visitor) {
        tokenValue.value = null;
        _token.value = null;
        visitorValue.value = visitor;
        _visitor.value = visitor;
        isAuth.value = false;
      }

      if (cart) {
        cartIdValue.value = cart;
        _cart.value = cart;
      }

      return response;
    },
    onResponseError({ response }: any) {
      if (response?._data?.data?.category === 'graphql-authorization') {
        _token.value = null;
        _visitor.value = null;
        _cart.value = null;

        window.location.reload();
      }

      throw createError(response?._data);
    },
  };

  return $fetch(requestUrl, {
    ...fetchDefaults,
    ...options,
  });
};

export const fetchApiGet = (requestUrl: string, opts?: any) => {
  const { data: params = {}, ...options } = opts ?? {};

  return fetchApi(requestUrl, {
    method: 'GET',
    params,
    ...options,
  });
};

export const fetchApiPost = (requestUrl: string, opts?: any) => {
  const { data: body = {}, ...options } = opts ?? {};

  return fetchApi(requestUrl, {
    method: 'POST',
    body,
    ...options,
  });
};

export const fetchApiPut = (requestUrl: string, opts?: any) => {
  const { data: body = {}, ...options } = opts ?? {};

  return fetchApi(requestUrl, {
    method: 'PUT',
    body,
    ...options,
  });
};

export const fetchApiDelete = (requestUrl: string, opts?: any) => {
  const { data: body = {}, ...options } = opts ?? {};

  return fetchApi(requestUrl, {
    method: 'DELETE',
    body,
    ...options,
  });
};
