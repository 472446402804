export const LOGO = '/images/logo.png';

export const MAX_OPTIONS_FILTER: number = 10;

export const VALIDATORS = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  name: /^(?:[абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'ʼ`"-]+(?:-[абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'ʼ`"-]+)*)(?:\s[абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'ʼ`"-]+(?:-[абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'ʼ`"-]+)?)?$/u,
  locale: {
    ua: /^(?!.*[-]{2})[абвгґдеєжзиіїйклмнопрстуфхцчшщьюяАБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЬЮЯ'ʼ'`"-]+$/u,
    en: /^(?!.*[-]{2})[a-zA-Z'-]+$/u,
  },
} as any;

export const MASKS = {
  phone: '+380 ## ### ## ##',
} as any;

export const MAX_AGE: number = 60 * 60 * 24 * 365;

export const URLKEY_PAGE_CONDITIONS = 'terms-and-conditions';
export const URLKEY_PAGE_POLICY = 'privacy-policy';
