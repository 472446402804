<template>
  <NuxtLoadingIndicator color="#E9661D" />

  <NuxtLayout>
    <NuxtPage />

    <ClientOnly>
      <Loader
        v-if="pending"
        position="fixed"
      />
    </ClientOnly>
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { Loader } from '@shared/Loader';
import { LOGO } from '@constants';

const { addSeoMeta } = useSeo();

const { getMainUser } = useMain();

const { initialize } = useGtag(); // init Google Tag Manager

const $route = useRoute();

const { locale, locales } = useI18n();

const localHead = useLocaleHead({
  addSeoAttributes: {
    canonicalQueries: ['book'],
  },
});

const { meta } = storeToRefs(useSeoStore());

const { pending, store } = storeToRefs(useMainStore());

const { viewedProducts } = storeToRefs(useAnalyticsStore());

const path = computed(() => $route.path);
const query = computed(() => $route.query);

const metaTitle = computed(() => store.value?.metaTitle);
const metaDescription = computed(() => store.value?.metaDescription);

if (store.value?.isGtag) {
  initialize(store.value?.gtagId);
}

meta.value = {
  logo: LOGO,
  title: metaTitle.value || store.value?.siteName,
  description: metaDescription.value,
  siteName: store.value?.siteName,
};

watch(
  localHead,
  (value) => {
    const { protocol, host, pathname, origin } = useRequestURL();
    const queryPage = query.value?.page;
    const fullPath = `${protocol}//${host}${pathname}${queryPage ? `?page=${queryPage}` : ''}`;
    const linkUrl = `${path.value}${queryPage ? `?page=${queryPage}` : ''}`;
    const links = [
      {
        rel: 'alternate',
        href: `${origin}${linkUrl}`,
        hreflang: 'uk-UA',
      },
      {
        rel: 'alternate',
        href: `${origin}${linkUrl}`,
        hreflang: 'x-default',
      },
      {
        rel: 'canonical',
        href: fullPath,
      },
    ];

    locales.value.forEach((el) => {
      if (el.code !== 'ua') {
        links.push({
          rel: 'alternate',
          href: `${origin}/${el.code}${linkUrl}`,
          hreflang: el.code,
        });
      }
    });

    useHead({
      htmlAttrs: {
        lang: value?.htmlAttrs?.lang,
      },
      link: [...links],
      meta: [...(value?.meta ?? [])],
    });

    addSeoMeta();
  },
  {
    immediate: true,
    deep: true,
  }
);

watch(locale, () => {
  getMainUser();
});

watch(query, () => {
  viewedProducts.value = [];
});

onMounted(() => {
  getMainUser();
});
</script>
