export const useWishlistStore = defineStore('wishlistStore', () => {
  const pending = ref<boolean>(false);
  const pendingUpdate = ref<boolean>(false);
  const wishlist = ref<any>();

  return {
    pending,
    pendingUpdate,
    wishlist,
  };
});
