export const useUserStore = defineStore('userStore', () => {
  const pending = ref<boolean>(false);
  const user = ref<any>();

  const userAvatar = computed(
    (): string | null => (user.value?.firstname || user.value?.lastname)?.trim()?.charAt(0)?.toUpperCase() ?? null
  );

  const userFullName = computed((): string | null => {
    const firstName = user.value?.firstname;
    const lastName = user.value?.lastname;

    if (!firstName || !lastName) {
      return null;
    }

    return [firstName, lastName].join(' ');
  });

  const isUser = computed((): boolean => !!user.value?.phone);

  return {
    pending,
    user,
    userAvatar,
    userFullName,
    isUser,
  };
});
